<template>
  <div>
    <div class="info" v-for="(v,i) in list" :key="i">
      <div>网点编号：{{v.websiteNumber}}</div>
      <div>网点名称：{{v.websiteName}}</div>
      <div>网点属性：{{v.attr}}</div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      let res = await this.getRequest("/wechat/miniProgramQueryUser");
      let type = await this.getRequest("/wechat/miniProgramBelongType");
      for (let i = 0; i < type.data.length; i++) {
        for (let j = 0; j < res.data.length; j++) {
          if (type.data[i].type === res.data[j].websiteType) {
            res.data[j].attr = type.data[i].desc;
          }
        }
      }
      this.list = res.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  font-size: 14px;
  background-color: #fff;
  padding-top: 20px;
  margin-top: 20px;
  div {
    padding: 0 0 20px 17px;
  }
}
</style>